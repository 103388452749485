<template>
  <div class="page-container">
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body">
          <div class="top-image">
            <img src="@/assets/img/index/icon-company.png" alt="">
          </div>
          <div class="company-box">
            <span class="company">{{ serviceData?.developerName }}</span> <span
              class="version" v-show="serviceData && serviceData.versionName">{{ serviceData?.versionName }}</span>
          </div>
          <div class="info-box">
            <div class="item">
              <div class="info">
                <span class="label">管理员</span>
                <span class="value">{{ serviceData?.admin }} {{ serviceData?.adminPhone }}</span>
              </div>
              <el-button type="primary" size="small" @click="changeAdmin"
                         v-if="serviceData && serviceData.canChange">
                变更
              </el-button>
            </div>
            <div class="item">
              <div class="info">
                <span class="label">电子签分数</span>
                <span class="value">{{ serviceData?.signSize || '-' }}</span>
              </div>
              <!--<el-button type="primary" size="small" round>购买</el-button>-->
            </div>
            <div class="item">
              <div class="info">
                <span class="label">服务到期时间</span>
                <span class="value">{{ serviceData?.endTime || '-' }}</span>
              </div>
              <!--<el-button type="primary" size="small" round>续费</el-button>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--变更管理员弹框-->
    <el-dialog title="管理员变更" :visible.sync="admin_dialog" width="35%"
               :close-on-click-modal="false">
      <div>
        <el-form :model="adminForm" :rules="adminRules" label-width="110px" ref="adminForm">
          <el-form-item label="请选择管理员" prop="adminId">
            <el-select v-model="adminForm.adminId" clearable style="width: 100%;" :placeholder="'请选择'">
              <el-option v-for="iter in admin_options"
                         :key="iter.id"
                         :label="iter.name"
                         :value="iter.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="admin_dialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="adminSubmit('adminForm')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {allAdmins, changeAdmin, indexServiceInfo} from "@/api/common";

export default {
  data() {
    return {
      serviceData: null,

      admin_options: [],
      admin_dialog: false,
      adminForm: {
        adminId: '',
      },
      adminRules: {
        adminId: [
          {required: true, message: '请选择管理员', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.getServiceInfo()
    this.getAllAdmin()
  },
  methods: {
    getServiceInfo() {
      indexServiceInfo().then(res => {
        this.serviceData = res
      })
    },
    getAllAdmin() {
      allAdmins().then(res => {
        this.admin_options = res
      })
    },
    // 变更管理员
    changeAdmin() {
      if(this.serviceData?.canChange){
        this.adminForm.adminId = ''
        this.admin_dialog = true
      }
    },
    adminSubmit(formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (!valid) return
        changeAdmin(that.adminForm.adminId).then(() => {
          that.getServiceInfo()
          that.admin_dialog = false
          that.$message.success('操作成功')
        }).catch(err => {
          console.log('变更管理员失败', err)
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.page-card-body {
  width: 1000px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;

  .top-image {
    text-align: center;

    & > img {
      width: 120px;
      height: 120px;
    }
  }

  .company-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 40px;
    text-align: center;

    .company {
      font-weight: 500;
      font-size: 36px;
      color: #18191A;
      line-height: 44px;
      padding-right: 24px;
    }

    .version {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      color: #f6f6f6;
      background: #FA9600;
      border-radius: 5000px;
      padding: 0 16px;
      font-size: 14px;
    }
  }

  .info-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .item {

      .info {
        text-align: left;
        margin-bottom: 16px;
        font-size: 14px;

        .label {
          color: #8D9094;
          padding-right: 24px;
        }

        .value {
          color: #2F3133;
        }
      }
    }
  }
}

</style>